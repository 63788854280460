<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Enter Feedback</h1>
            </div>
        </div>
        <div>
           
        </div>
        <div>
            <iframe class="airtable-embed" src="https://airtable.com/embed/shrYlIQDeTl5qDbhs?backgroundColor=purpleLight" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
        </div>


       

    </div>
</template>

<script>
   

    

    import { mapState,mapActions } from 'vuex'
    export default {
        name: `Enter Feedback`,
        data: function () {
            return {
                LayoutModel: {},
                data: [],
                NameExisted: [],
                //=== edit popup====
                editId: 0,
                showEditForm: false,
                editTitle: 'Edit',
                editBtnText: 'Save',
                Name:'',
            }
        },
       
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                         
            },
            
        },

        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            },
           
        }
    };
</script>
